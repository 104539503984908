body {
  --primary-color: #11721f;
  --secondary-color: #721164;
  background-color: #efedd0;
  color: var(--primary-color);
  font-size: 16px;
  text-align: center;
}

.App-header {
  margin: auto;
  width: 33%;
  padding: 1rem;
}

.bill {
  align-items: center;
  display: flex;
  justify-content: stretch;
  margin: 0.25rem;
  padding: 0.5rem;
  border-bottom: dotted 1px var(--primary-color);
}

.bill.budget {
  color: var(--secondary-color);
}

.bill:nth-child(odd) {
  background: rgba(255, 255, 255, 0.1);
}

.textmode__field {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
}

.textmode__label {
  font-family: monospace;
  font-size: 1rem;
  margin-left: 1rem;
}

.textmode__switch {
}

.bill__title {
  flex: 1;
  text-align: left;
  font-family: Georgia, serif;
}

.bill__price {
  flex: 1;
  text-align: right;
  font-family: Georgia, serif;
}

.bill__title a,
.bill__title a:visited {
  color: var(--primary-color);
}

.bill__title a:hover {
  color: var(--secondary-color);
}

.bill__textfield {
  border: dotted 1px var(--primary-color);
  display: block;
  font-family: monospace;
  font-size: 18px;
  margin: 0.5rem;
  padding: 0.25rem;
  width: 100%;
}
